import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const officeMailSlice = createSlice({
  name: "officeMail",
  initialState,
  reducers: {
    fetchDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { fetchDataSuccess } = officeMailSlice.actions;
export default officeMailSlice.reducer;
