import React from "react";
import { ReactComponent as Forward } from "../assets/images/forward.svg";
import { ReactComponent as DownArrow } from "../assets/images/downArrow.svg";
import "../assets/css/thankYou.scss";

const ThankYou = () => {
  return (
    <div className="pageInner">
      <div className="contentBlock">
        <h2 className="contentBlock__title">Tear up the contract</h2>
        <p className="contentBlock__text">
          Soon, you will get to know a new office
        </p>
      </div>
      <div className="messageBox">
        <div className="messageBox__head">
          <h6 className="messageBox__title">Wake up your CEO</h6>
          <p className="messageBox__text">Share the message</p>
          <DownArrow />
        </div>
        <div className="messageBox_items">
          <div className="msgBlock">
            <div className="msgBlock__head">
              <button>
                <Forward />
              </button>
              <h5 className="msgBlock__title">Hey CEO,</h5>
              <h6 className="msgBlock__text">
                Tear up the
                <br /> contract
              </h6>
            </div>
            <div className="msgBlock__footer">#hetkantoorstaatleeg</div>
          </div>
          <div className="msgBlock">
            <div className="msgBlock__head">
              <button>
                <Forward />
              </button>
              <h5 className="msgBlock__title">Hey CEO,</h5>
              <h6 className="msgBlock__text">
                Nobody likes <br /> your office
              </h6>
            </div>
            <div className="msgBlock__footer">#hetkantoorstaatleeg</div>
          </div>
          <div className="msgBlock">
            <div className="msgBlock__head">
              <button>
                <Forward />
              </button>
              <h5 className="msgBlock__title">Hey CEO,</h5>
              <h6 className="msgBlock__text">
                Your office is empty. <br /> Ditch it.
              </h6>
            </div>
            <div className="msgBlock__footer">#hetkantoorstaatleeg</div>
          </div>
          <div className="msgBlock">
            <div className="msgBlock__head">
              <button>
                <Forward />
              </button>
              <h5 className="msgBlock__title">Hey CEO,</h5>
              <h6 className="msgBlock__text">
                Rest in peace <br />
                my dear office
              </h6>
            </div>
            <div className="msgBlock__footer">#hetkantoorstaatleeg</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
