import React from "react";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../assets/css/home.scss";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="pageInner">
      <div className="contentBlock">
        <h2 className="contentBlock__title">
          The office <br />
          Game over!
        </h2>
        <div className="contentBlock__btn">
          <Link to="/form">
            <Button
              text="Get to know a better way"
              endIcon={
                <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
              }
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
