import React from "react";
import "../assets/css/button.scss";

const Button = (props) => {
  return (
    <button
      className={`btn ${props?.className ? props?.className : ""}`}
      onClick={props.onClick}
    >
      {props?.startIcon ? props.startIcon : ""} {props.text}{" "}
      {props?.endIcon ? props.endIcon : ""}
    </button>
  );
};

export default Button;
