import "./App.scss";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Form from "./pages/Form";
import ThankYou from "./pages/ThankYou";
import Mail from "./pages/Mail";
import Landing from "./pages/Oplossing";
import pushToDataLayer from "./gtm-datalayer-push";

function Inner() {
  const location = useLocation();
  useEffect(() => {
    const dataLayerEvent = {
      event: "page_view",
      page: location.pathname + location.search,
    };
    pushToDataLayer(dataLayerEvent);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/form" element={<Form />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/get-in-touch" element={<Mail />} />
      <Route path="/oplossing" element={<Landing />} />
    </Routes>
  );
}

function App() {
  return (
    <div className="pageWrapper">
      <div className="pageWrapper__head">
        <h4 className="pageWrapper__head-text">#hetkantoorstaatleeg</h4>
      </div>
      <div className="pageWrapper__body">
        <h5 className="pageWrapper__title">Hey CEO...</h5>
        <Router>
          <Inner />
        </Router>
      </div>
    </div>
  );
}

export default App;
