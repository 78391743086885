import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Hundred500_100 from "../assets/images/100-500m2/100-500m2.png";
import Hundred500_80 from "../assets/images/100-500m2/100-500m2 80_.png";
import Hundred500_50 from "../assets/images/100-500m2/100-500m2 50_.png";
import Hundred500_20 from "../assets/images/100-500m2/100-500m2 20_.png";

import FiveHundred1000_100 from "../assets/images/500-1000m2/500-1000.png";
import FiveHundred1000_80 from "../assets/images/500-1000m2/500-1000 80_.png";
import FiveHundred1000_50 from "../assets/images/500-1000m2/500-1000 50_.png";
import FiveHundred1000_20 from "../assets/images/500-1000m2/500-1000 20_.png";

import Thousand2000_100 from "../assets/images/1000-2000m2/1000-2000.png";
import Thousand2000_80 from "../assets/images/1000-2000m2/1000-2000  80_.png";
import Thousand2000_50 from "../assets/images/1000-2000m2/1000-2000 50_.png";
import Thousand2000_20 from "../assets/images/1000-2000m2/1000-2000 20_.png";

import Lessthan2000_100 from "../assets/images/2000m2/_2000.png";
import Lessthan2000_80 from "../assets/images/2000m2/_2000  80_.png";
import Lessthan2000_50 from "../assets/images/2000m2/_2000 50_.png";
import Lessthan2000_20 from "../assets/images/2000m2/_2000 20_.png";

import pushToDataLayer from "../gtm-datalayer-push";

import Yes from "../assets/images/Yes.png";
import No from "../assets/images/No.png";
import Maybe from "../assets/images/Maybe.png";

import { fetchDataSuccess } from "../redux/slices/officeMailSlice";
import { fetchDataSuccess as stepDataSuccess } from "../redux/slices/stepSlice";
import "../assets/css/form.scss";

const Step1 = ({ step, setStep }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state?.officeMail);
  const [officeSize, setOfficeSize] = useState(
    data?.officeSize ? data?.officeSize : "100-500 m2"
  );

  useEffect(() => {
    const finalData = { ...data, officeSize };
    dispatch(fetchDataSuccess(finalData));
  }, [officeSize]);
  return (
    <>
      <div className="selectorBlock">
        <h3 className="selectorBlock__title">How big is your office?</h3>
        <div className="selectorBlock__sizeButtons">
          <Button
            text="100-500 m2"
            className={`${officeSize === "100-500 m2" ? "active" : ""}`}
            onClick={() => setOfficeSize("100-500 m2")}
          />
          <Button
            text="500-1000 m2"
            className={`${officeSize === "500-1000 m2" ? "active" : ""}`}
            onClick={() => setOfficeSize("500-1000 m2")}
          />
          <Button
            text="1000-2000 m2"
            className={`${officeSize === "1000-2000 m2" ? "active" : ""}`}
            onClick={() => setOfficeSize("1000-2000 m2")}
          />
          <Button
            text="> 2000 m2"
            className={`${officeSize === "> 2000 m2" ? "active" : ""}`}
            onClick={() => setOfficeSize("> 2000 m2")}
          />
        </div>
      </div>
      <div className="stepper__footer">
        {
          <img
            src={
              data.officeSize === "100-500 m2"
                ? Hundred500_100
                : data.officeSize === "500-1000 m2"
                ? FiveHundred1000_100
                : data.officeSize === "1000-2000 m2"
                ? Thousand2000_100
                : data.officeSize === "> 2000 m2" && Lessthan2000_100
            }
            alt="" width="300" height="300"
          />
        }
        <div className="stepper__nav">
          <Button text="Previous" />
          <div className="stepper__nav-text">{step} / 3</div>
          <Button
            text="Next"
            endIcon={
              <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
            }
            onClick={() => setStep(step + 1)}
          />
        </div>
      </div>
    </>
  );
};

const Step2 = ({ step, setStep }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state?.officeMail);
  const [officeEmployees, setOfficeEmployees] = useState(
    data?.officeEmployees ? data?.officeEmployees : "100%"
  );

  useEffect(() => {
    const finalData = { ...data, officeEmployees };
    dispatch(fetchDataSuccess(finalData));
  }, [officeEmployees]);

  return (
    <>
      <div className="selectorBlock">
        <h3 className="selectorBlock__title">
          How many employees work at the office?
        </h3>
        <div className="selectorBlock__average">
          <h6 className="selectorBlock__average-title">Average</h6>
          <div className="selectorBlock__average-btn">
            <Button
              text="20%"
              className={`${officeEmployees === "20%" ? "active" : ""}`}
              onClick={() => setOfficeEmployees("20%")}
            />
            <Button
              text="50%"
              className={`${officeEmployees === "50%" ? "active" : ""}`}
              onClick={() => setOfficeEmployees("50%")}
            />
            <Button
              text="80%"
              className={`${officeEmployees === "80%" ? "active" : ""}`}
              onClick={() => setOfficeEmployees("80%")}
            />
            <Button
              text="100%"
              className={`${officeEmployees === "100%" ? "active" : ""}`}
              onClick={() => setOfficeEmployees("100%")}
            />
          </div>
        </div>
      </div>
      <div className="stepper__footer">
        {data.officeSize === "100-500 m2" && (
          <img
            src={
              officeEmployees === "100%"
                ? Hundred500_100
                : officeEmployees === "80%"
                ? Hundred500_80
                : officeEmployees === "50%"
                ? Hundred500_50
                : officeEmployees === "20%" && Hundred500_20
            }
            alt="" width="300" height="300"
          />
        )}
        {data.officeSize === "500-1000 m2" && (
          <img
            src={
              officeEmployees === "100%"
                ? FiveHundred1000_100
                : officeEmployees === "80%"
                ? FiveHundred1000_80
                : officeEmployees === "50%"
                ? FiveHundred1000_50
                : officeEmployees === "20%" && FiveHundred1000_20
            }
            alt="" width="300" height="300"
          />
        )}
        {data.officeSize === "1000-2000 m2" && (
          <img
            src={
              officeEmployees === "100%"
                ? Thousand2000_100
                : officeEmployees === "80%"
                ? Thousand2000_80
                : officeEmployees === "50%"
                ? Thousand2000_50
                : officeEmployees === "20%" && Thousand2000_20
            }
            alt="" width="300" height="300"
          />
        )}
        {data.officeSize === "> 2000 m2" && (
          <img
            src={
              officeEmployees === "100%"
                ? Lessthan2000_100
                : officeEmployees === "80%"
                ? Lessthan2000_80
                : officeEmployees === "50%"
                ? Lessthan2000_50
                : officeEmployees === "20%" && Lessthan2000_20
            }
            alt="" width="300" height="300"
          />
        )}
        <div className="stepper__nav">
          <Button
            text="Previous"
            startIcon={
              <FontAwesomeIcon
                icon={faArrowRight}
                className="arrow-icon"
                style={{ transform: "rotate(180deg)" }}
              />
            }
            onClick={() => setStep(step - 1)}
          />
          <div className="stepper__nav-text">{step} / 3</div>
          <Button
            text="Next"
            endIcon={
              <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
            }
            onClick={() => setStep(step + 1)}
          />
        </div>
      </div>
    </>
  );
};

const Step3 = ({ step, setStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state?.officeMail);
  const [officeLike, setOfficeLike] = useState(
    data.officeLike ? data.officeLike : "Yes"
  );

  useEffect(() => {
    const finalData = { ...data, officeLike };
    dispatch(fetchDataSuccess(finalData));
  }, [officeLike]);

  return (
    <>
      <div className="selectorBlock">
        <h3 className="selectorBlock__title">
          Do your employees like the office?
        </h3>
        <div className="selectorBlock__option">
          <Button
            text="Yes"
            className={`${officeLike === "Yes" ? "active" : ""}`}
            onClick={() => setOfficeLike("Yes")}
          />
          <Button
            text="Maybe"
            className={`${officeLike === "Maybe" ? "active" : ""}`}
            onClick={() => setOfficeLike("Maybe")}
          />
          <Button
            text="No"
            className={`${officeLike === "No" ? "active" : ""}`}
            onClick={() => setOfficeLike("No")}
          />
        </div>
      </div>
      <div className="stepper__footer">
        {
          <img
            src={
              officeLike === "Yes"
                ? Yes
                : officeLike === "No"
                ? No
                : officeLike === "Maybe" && Maybe
            }
            alt="" width="300" height="300"
          />
        }
        <div className="stepper__nav">
          <Button
            text="Previous"
            startIcon={
              <FontAwesomeIcon
                icon={faArrowRight}
                className="arrow-icon"
                style={{ transform: "rotate(180deg)" }}
              />
            }
            onClick={() => setStep(step - 1)}
          />
          <div className="stepper__nav-text">{step} / 3</div>

          <Button
            text="Next"
            onClick={() => navigate("/get-in-touch")}
            endIcon={
              <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
            }
          />
        </div>
      </div>
    </>
  );
};

const Form = () => {
  const { data } = useSelector((state) => state?.step);
  const [step, setStep] = useState(data);
  const dispatch = useDispatch();

  useEffect(() => {
    const dataLayerEvent = {
      event: 'form_page',
      step: step,
    };
    pushToDataLayer(dataLayerEvent);
    dispatch(stepDataSuccess(step));
  }, [step]);

  return (
    <div className="pageInner">
      <div className="stepper">
        {step === 1 ? (
          <Step1 step={step} setStep={setStep} />
        ) : step === 2 ? (
          <Step2 step={step} setStep={setStep} />
        ) : (
          <Step3 step={step} setStep={setStep} />
        )}
      </div>
    </div>
  );
};

export default Form;
