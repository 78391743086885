import React from "react";
import { useSelector } from "react-redux";
import Button from "../components/Button";
import { ReactComponent as Email } from "../assets/images/email.svg";
import { ReactComponent as Office } from "../assets/images/office.svg";
import "../assets/css/maybe.scss";

const Maybe = () => {
  const { data } = useSelector((state) => state.officeMail);

  const ACTIVE_CAMPAIGN_FORM_ACTION_URL = "https://mrgreenboutiqueoffices.activehosted.com/proc.php?"

  const NewsletterSubscribeForm = ({ formId }) => {
    return (
      <div className="newsletter-subscribe-form">
        <form action={ACTIVE_CAMPAIGN_FORM_ACTION_URL} method="POST" id="_form_newsletter_" className="_form _form_newsletter _inline-form  _dark">
          <input type="hidden" name="u" value={formId} />
          <input type="hidden" name="f" value={formId} />
          <input type="hidden" name="s" />
          <input type="hidden" name="c" value="0" />
          <input type="hidden" name="m" value="0" />
          <input type="hidden" name="act" value="sub" />
          <input type="hidden" name="v" value="2" />
          <div className="_form-content">
            <div className="contentBlock__form-field">
              <input
                type="email"
                name="email"
                required
                placeholder="Leave your mail here"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              />
              <Button
                text="Help me out!"
                id="_form_newsletter_submit" className="_submit" type="submit"
                startIcon={<Email />}
              ></Button>
            </div>
          </div>
        </form>
      </div>
    )
  }

  return (
    <div className="pageInner">
      <div className="contentBlock">
        <h2 className="contentBlock__title contentBlock__title--small">
          There is a<br />
          better way
        </h2>
        <div className="contentBlock__form">
          <NewsletterSubscribeForm formId={26}/>
        </div>
      </div>
      <div className="emptyBlock">
        <div className="emptyBlock__text">
          <p>
            {data.officeLike === "No"
              ? "Stop burning cash"
              : data.officeLike === "Yes"
              ? "Flexibility"
              : data.officeLike === "Maybe" && "Paying for unused space?"}
          </p>
        </div>
        <div className="emptyBlock__img">
          <h5>Your office is empty. Rethink it.</h5>
          <Office />
        </div>
        <div className="emptyBlock__text">
          <p>
            {data.officeLike === "No"
              ? "Ditch the office"
              : data.officeLike === "Yes"
              ? "Less cost"
              : data.officeLike === "Maybe" && "No more boring office"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Maybe;
