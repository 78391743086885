import { configureStore } from "@reduxjs/toolkit";
import officeMailSlice from "./slices/officeMailSlice";
import stepSlice from "./slices/stepSlice";

const store = configureStore({
  reducer: {
    officeMail: officeMailSlice,
    step: stepSlice,
  },
});

export default store;
